import styled from "styled-components";
import { Link, type ThemeType, Box } from "@cruk/cruk-react-components";

export const ProfileOuterWrapper = styled.div`
  position: relative;
`;

export const LeaderStar = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: -1.5em;
`;

export const ShadowWrapper = styled.div<{ theme: ThemeType }>`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.m};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  padding: ${({ theme }) => theme.spacing.xs};
`;

export const LinkElipsis = styled(Link)<{ theme: ThemeType }>`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${({ theme }) => theme.typography.lineHeight};
`;

export const MemberLink = styled.span`
  text-decoration: none;
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

export const ProfileImageWrapper = styled.div<{
  theme: ThemeType;
  $isLeader: boolean;
}>`
  position: relative;
  width: 5em;
  height: 5em;
  min-width: 5em;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: bottom;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ theme, $isLeader }) =>
    $isLeader ? theme.colors.secondary : theme.colors.avatarBorder};
  transition: border 0.3s ease;

  &:hover {
    border-width: 6px;
  }
`;

export const ProfileImage = styled.img`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 1000px;
`;

export const TextWrapper = styled(Box)<{ theme: ThemeType }>`
  min-height: ${({ theme }) => `calc(3 * ${theme.typography.lineHeight})`};
  overflow: hidden;
  width: 100%;
`;
