import { type TeamMemberType } from "@fwa/src/types";
import {
  AvatarFull,
  FirstPlace,
  PodiumWrapper,
  PositionWrapper,
  UserIconWrapper,
} from "@fwa/src/components/TeamMembersOverviewPodium/styles";
import { Text } from "@cruk/cruk-react-components";
import { imagePathFromImageType } from "@fwa/src/services/imageService";

type MedalArrayType = "gold" | "silver" | "bronze";

const PodiumMember = ({
  member,
  medalColor,
}: {
  member: TeamMemberType;
  medalColor: MedalArrayType;
}) => {
  if (!member) return null;

  const name = member.childPage.owner?.displayName
    ? member.childPage.owner?.displayName
    : member.childPage.fundraiser.forename;
  // if image avatar image url is undefined use a default letter.
  const fundraiserProfileImageUrl = member.childPage.fundraiser.profileImage
    ? imagePathFromImageType(member.childPage.fundraiser.profileImage)
    : undefined;
  // user with a displayname should not use profile image here.
  const imageUrl = member.childPage.owner?.displayName
    ? undefined
    : fundraiserProfileImageUrl;

  return (
    <PositionWrapper key={member.uniqueId} $medal={medalColor}>
      <UserIconWrapper key={member.uniqueId} $medal={medalColor}>
        <AvatarFull
          name={name}
          url={imageUrl}
          alt={`${member.childPage.fundraiser.forename} is currently winning the ${medalColor} medal`}
        />
        <AvatarFull />
      </UserIconWrapper>
      {medalColor === "gold" && (
        <FirstPlace>
          <Text>1st</Text>
        </FirstPlace>
      )}
    </PositionWrapper>
  );
};

export const TeammMembersOverviewPodium = ({
  members,
}: {
  members: TeamMemberType[];
}) => (
  <PodiumWrapper marginTop="xl" marginBottom="s">
    <PodiumMember member={members[0] || undefined} medalColor="gold" />
    <PodiumMember member={members[1] || undefined} medalColor="silver" />
    <PodiumMember member={members[2] || undefined} medalColor="bronze" />
  </PodiumWrapper>
);

export default TeammMembersOverviewPodium;
