import styled from "styled-components";

export const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

export default FlexWrap;
