import { type ReactNode, useState } from "react";
import { useTheme } from "styled-components";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Collapse,
  IconFa,
  type ThemeType,
} from "@cruk/cruk-react-components";

import {
  FlippingIcon,
  HeaderText,
} from "@fwa/src/components/CollapsableSection/styles";

type Props = {
  id: string;
  headerTitleText: string;
  headerBody?: ReactNode;
  headerIcon?: ReactNode;
  startOpen?: boolean;
  handleOpenChanged?: (isOpen: boolean) => void;
  children: ReactNode;
};

export const CollapsableSection = ({
  id,
  headerTitleText,
  headerBody,
  headerIcon,
  startOpen = false,
  children,
  handleOpenChanged,
}: Props) => {
  const [settingsOpen, setSettingsOpen] = useState(startOpen);
  const handleSettingsOpen = (value: boolean) => {
    if (handleOpenChanged) {
      handleOpenChanged(value);
    }
    setSettingsOpen(value);
  };

  const theme = useTheme();
  const typedTheme = theme as ThemeType;
  return (
    <Collapse
      id={id}
      headerComponent={
        headerIcon || headerBody ? (
          <Box>
            <div>
              {headerIcon ? (
                <Box as="span" paddingRight={headerIcon ? "xs" : "none"}>
                  {headerIcon}
                </Box>
              ) : null}
              <HeaderText>{headerTitleText}</HeaderText>
              <FlippingIcon $open={settingsOpen}>
                <IconFa faIcon={faChevronDown} />
              </FlippingIcon>
            </div>
            {headerBody}
          </Box>
        ) : null
      }
      headerTitleText={headerTitleText}
      startOpen={startOpen}
      headerTitleTextColor="primary"
      headerTitleTextSize="l"
      headerTitleTextFontFamily={typedTheme.typography.fontFamilyHeadings}
      onOpenChange={handleSettingsOpen}
    >
      {children}
    </Collapse>
  );
};

export default CollapsableSection;
