const MemberPageCrukSvg = "/assets/images/img/member-page-cruk-128.svg";
const MemberPageSu2cSvg = "/assets/images/img/member-page-su2c-128.svg";
const MemberPageBbSvg = "/assets/images/img/member-page-bb-128.svg";
const MemberTeamCrukSvg = "/assets/images/img/member-team-cruk-128.svg";
const MemberTeamSu2cSvg = "/assets/images/img/member-team-su2c-128.svg";
const MemberTeamBbSvg = "/assets/images/img/member-team-bb-128.svg";

export const memberPageIconUrlForTheme = (themeName: string): string => {
  switch (themeName) {
    case "su2c":
      return MemberPageSu2cSvg;
    case "bowelbabe":
      return MemberPageBbSvg;
    default:
      return MemberPageCrukSvg;
  }
};
export const memberTeamIconUrlForTheme = (themeName: string): string => {
  switch (themeName) {
    case "su2c":
      return MemberTeamSu2cSvg;
    case "bowelbabe":
      return MemberTeamBbSvg;
    default:
      return MemberTeamCrukSvg;
  }
};
