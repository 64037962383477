import styled from "styled-components";
import { type ThemeType } from "@cruk/cruk-react-components";

export const FlippingIcon = styled.span<{
  $open: boolean;
  theme: ThemeType;
}>`
  display: inline-block;
  transform: ${({ $open }) =>
    $open ? "translateY(0.1em) scaleY(-1)" : "initial"};
  transition: transform linear 0.5s;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: ${({ theme }) => theme.spacing.xxs};
`;

export const HeaderText = styled.span<{ theme: ThemeType }>`
  font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
  font-size: ${({ theme }) => theme.fontSizes.l};
  color: ${({ theme }) => theme.colors.primary};
  text-transform: ${({ theme }) =>
    theme.name === "su2c" ? "uppercase" : "none"};
`;
