import { type MouseEvent, useEffect } from "react";
import { IconFa, Link, ProgressBar, Text } from "@cruk/cruk-react-components";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { useActiveSectionContext } from "@fwa/src/contexts/ActiveSectionContext";

import { List, ScreenReaderOnly } from "@fwa/src/components/styles";
import {
  ProgressRow,
  RowStretchCentered,
  ProgressButton,
  BlockCentreMobile,
} from "@fwa/src/components/ProgressList/styles";

import {
  type ProgressItemType,
  type PageActiveSectionType,
} from "@fwa/src/types";

export const completionStats = (progressItems: ProgressItemType[]) => {
  const doneCount: number =
    progressItems.reduce(
      (accumulator, progressItem): number =>
        progressItem.isDone ? accumulator + 1 : accumulator,
      0,
    ) || 0;
  const percentageComplete = Math.floor(
    (doneCount / progressItems.length) * 100,
  ); // floor or round here? difference is basically 66% vs 67% for 2/3
  const complete = percentageComplete === 100;

  return { doneCount, percentageComplete, complete };
};

type ProgressListProps = {
  progressItems: ProgressItemType[];
};

let percentageCompleteOnFirstLoad = 0;

export const ProgressList = ({ progressItems }: ProgressListProps) => {
  const { trackEvent } = useOptimizelyContext();
  const [, setActiveSectionContext] = useActiveSectionContext();
  const handleLinkClick = (fieldName?: PageActiveSectionType) => {
    if (!fieldName) return;
    const element = document.getElementById(fieldName);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });

    setTimeout(() => {
      setActiveSectionContext({
        activeSection: fieldName,
      });
    }, 1000);
  };

  const { percentageComplete } = completionStats(progressItems);

  useEffect(() => {
    percentageCompleteOnFirstLoad = percentageComplete;
  }, []);

  useEffect(() => {
    if (percentageComplete !== percentageCompleteOnFirstLoad) {
      trackEvent({
        eventKey: "of_progress_bar_completion",
        eventTags: { percentageComplete },
      });
    }
  }, [percentageComplete]);

  return (
    <>
      <List>
        {progressItems.map((progressItem: ProgressItemType) => (
          <li key={`progressItem_${progressItem.fieldName || ""}`}>
            <ProgressRow>
              <ProgressButton
                onClick={(e: MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  handleLinkClick(progressItem.fieldName);
                }}
                data-cta-type={progressItem.dataCtaType}
              >
                <RowStretchCentered>
                  <Text as="span" marginRight="xs" textColor="tertiary">
                    <ScreenReaderOnly>
                      {progressItem.isDone ? "completed" : "to do"}
                    </ScreenReaderOnly>
                    {progressItem.isDone ? (
                      <IconFa faIcon={faCircleCheck} size="1.5em" />
                    ) : (
                      <IconFa faIcon={faCircle} size="1.5em" />
                    )}
                  </Text>
                  <Link as="span">{progressItem.label}</Link>
                </RowStretchCentered>
              </ProgressButton>
            </ProgressRow>
          </li>
        ))}
      </List>
      <BlockCentreMobile>
        <ProgressBar
          isCircular
          percentage={percentageComplete}
          circleContents={`${percentageComplete}%`}
        />
      </BlockCentreMobile>
    </>
  );
};

export default ProgressList;
