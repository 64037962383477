import { Avatar, Box, type ThemeType } from "@cruk/cruk-react-components";
import { type MedalType } from "@fwa/src/types";
import styled from "styled-components";

const PODIUM_WIDTH = "14rem";
const LARGE_ICON = "4.75rem";
const MEDIUM_ICON = "4.5rem";
const SMALL_ICON = "4rem";

export const PodiumWrapper = styled(Box)`
  max-width: ${PODIUM_WIDTH};
  position: relative;
  margin: auto;
`;

export const PositionWrapper = styled.div<{
  $medal: MedalType | null;
}>`
  width: ${({ $medal }) => ($medal === "gold" ? "100%" : "50%")};
  display: inline-block;
`;

export const UserIconWrapper = styled.div<{
  theme: ThemeType;
  $medal: MedalType | null;
}>`
  width: ${({ $medal }) =>
    $medal === "gold"
      ? LARGE_ICON
      : $medal === "silver"
        ? MEDIUM_ICON
        : SMALL_ICON};
  height: ${({ $medal }) =>
    $medal === "gold"
      ? LARGE_ICON
      : $medal === "silver"
        ? MEDIUM_ICON
        : SMALL_ICON};
  min-width: ${({ $medal }) =>
    $medal === "gold"
      ? LARGE_ICON
      : $medal === "silver"
        ? MEDIUM_ICON
        : SMALL_ICON};
  overflow: hidden;
  margin: auto;
  background-color: ${({ theme }) => theme.tokenColors.gold};
  outline: 8px solid
    ${({ $medal }) =>
      $medal === "gold"
        ? "#FFCA00"
        : $medal === "silver"
          ? "#B4B4B4"
          : "#CC8B38"};
  border-radius: 50%;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.avatarBorder};
  border-width: 8px;
`;

export const AvatarFull = styled(Avatar)`
  width: 100%;
  height: 100%;
`;

export const FirstPlace = styled.div<{ theme: ThemeType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffca00;
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing.m};
  height: ${({ theme }) => theme.spacing.m};
  position: absolute;
  top: -1.5rem;
  right: 4rem;
`;
