import { type ThemeType, Box } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const TotaliserWrapper = styled.div`
  max-width: 560px;
`;

export const TotaliserButtonsFlex = styled.div<{ theme: ThemeType }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  > * {
    margin: ${({ theme }) => `0 ${theme.spacing.xs} ${theme.spacing.xs}`};
  }
`;

export const TeamLogo = styled.img<{ theme: ThemeType }>`
  height: ${({ theme }) => theme.spacing.xl};
  width: ${({ theme }) => theme.spacing.xl};
  border-radius: 50%;
  border: solid 1px ${({ theme }) => theme.colors.tertiary};
`;

export const TeamLabel = styled(Box)<{ theme: ThemeType }>`
  display: block;
  font-family: ${({ theme }) => theme.typography.fontFamilyHeadings};
  font-size: ${({ theme }) => theme.fontSizes.l};
`;
