import { useSwrGet } from "@fwa/src/hooks/useSwrGet";
import { Box, Text } from "@cruk/cruk-react-components";

import { fwsUrlTeamMembers } from "@fwa/src/services/teamService";
import { fwsUrlPageDefaultHeaderImages } from "@fwa/src/services/fundraisingPageService";
import { CollapsableSection } from "@fwa/src/components/CollapsableSection";
import { OnlyYou } from "@fwa/src/components/OnlyYou";
import {
  ProgressList,
  completionStats,
} from "@fwa/src/components/ProgressList";

import { TopBorderChunky, RowWrap } from "@fwa/src/components/styles";

import {
  type TeamPageType,
  type ImageType,
  type FwsResponseData,
  type ProgressItemType,
} from "@fwa/src/types";

type Props = {
  page: TeamPageType;
};

export const TeamPageProgress = ({ page }: Props) => {
  const membersUrl = fwsUrlTeamMembers({
    teamId: page.uniqueId,
    currentPage: 1,
    itemsPerPage: 2,
    sort: "leader",
  });
  const { totalItems } = useSwrGet(membersUrl, {});

  const headerImageUrl = page
    ? fwsUrlPageDefaultHeaderImages({
        pageId: page.uniqueId,
      })
    : null;
  const { data: headerImagesData } =
    useSwrGet<FwsResponseData<ImageType>>(headerImageUrl);
  const headerImages = headerImagesData?.results || [];

  const isDefaultHeader =
    page.headerImage &&
    headerImages.find(
      (image: ImageType) => image.uniqueId === page.headerImage?.uniqueId,
    );

  const hasStory = !!page?.story?.length;
  const hasImages = !isDefaultHeader;
  const hasMembers = totalItems > 1;

  const progressItems: ProgressItemType[] = [
    {
      label: "Set up your team page",
      isDone: true,
    },
    {
      label: `Personalise your team's story`,
      fieldName: "story",
      dataCtaType: "progress-edit-story",
      isDone: hasStory,
    },
    {
      label: `Add your team's cover image`,
      fieldName: "headerImage",
      dataCtaType: "progress-edit-header-image",
      isDone: hasImages,
    },
    {
      label: "Get team members to join",
      fieldName: "members",
      dataCtaType: "progress-invite-to-team",
      isDone: hasMembers,
    },
  ];

  const { complete } = completionStats(progressItems);

  return (
    <Box data-component="team-page-progress">
      <TopBorderChunky>
        <Box backgroundColor="backgroundLight" paddingBottom="none">
          {headerImagesData && (
            <CollapsableSection
              id="page-progress"
              headerTitleText="Personalise your Team Page"
              startOpen={!complete}
            >
              <OnlyYou />
              <Box paddingBottom="s">
                <Text>
                  {complete
                    ? "Nice work - your Team page is looking great!"
                    : "Every story matters. Use these quick links to help tell yours."}
                </Text>
                <RowWrap>
                  <ProgressList progressItems={progressItems} />
                </RowWrap>
              </Box>
            </CollapsableSection>
          )}
        </Box>
      </TopBorderChunky>
    </Box>
  );
};

export default TeamPageProgress;
