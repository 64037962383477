import styled from "styled-components";
import { Box, type ThemeType } from "@cruk/cruk-react-components";

const PODIUM_WIDTH = "14rem";

export const ShadowWrapper = styled.li<{ theme: ThemeType }>`
  width: 100%;
  border: ${({ theme }) => `dashed 2px ${theme.tokenColors.grey_300}`};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  padding: ${({ theme }) => theme.spacing.xs};
`;

export const FullWidthChild = styled.div`
  display: flex;
  * {
    flex: 1 1;
  }
`;

export const AvatarWrapper = styled(Box)`
  height: 5em;
  width: 5em;
  min-width: 5em;
  object-fit: fill;
`;

export const ButtonWrapper = styled(Box)`
  width: 100%;
`;

export const PodiumLinkWrapper = styled.div`
  max-width: ${PODIUM_WIDTH};
  margin: auto;
`;
