import { useState } from "react";
import { Text, Heading, ErrorText, Button } from "@cruk/cruk-react-components";

import { fwsUrlCheckUrl } from "@fwa/src/services/fundraisingPageService";
import { fetcher, FWA_BASE_URL } from "@fwa/src/services/apiClient";
import { isBrowser } from "@fwa/src/utils/browserUtils";

import { Editable } from "@fwa/src/components/Editable";
import { EditableTextField } from "@fwa/src/components/EditableTextField";

import { type CheckUrlType, type PageType } from "@fwa/src/types";

type Props = {
  url: string;
  handleEditData: (
    data: Partial<PageType>,
  ) => Promise<void | undefined | PageType>;
  route: "page" | "team";
};

export const EditableUrlForm = ({ url = "", handleEditData, route }: Props) => {
  const slug = url.split(`/${route}/`)[1];
  const [textState, setTextState] = useState<string>(slug);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [urlSuggestion, setUrlSuggestion] = useState<string>("");
  const urlRegex = /(?=^((?!--).)*$)^[0-9a-z][0-9a-z-]{1,}[0-9a-z]$/;
  const urlRegexMessage =
    "URLs must contain only lowercase alphanumeric characters and single dashes, and must not start or end with a dash.";

  const handleSubmit = () => {
    const newUrl = `${FWA_BASE_URL}/${route}/${textState}`;
    return fetcher(fwsUrlCheckUrl({ url: newUrl }))
      .then((res) => res as CheckUrlType)
      .then((data: CheckUrlType) => {
        if (data.isUnique) {
          setUrlSuggestion("");
        }
        if (!data.isUnique) {
          setUrlSuggestion(data.url);
        }
        return data;
      })
      .then((data) => {
        if (!data.isUnique) throw new Error("URL not unique");
        else {
          return handleEditData({
            urlPath: `/${route}/${textState}` || "",
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleSuggestionClick = () => {
    const newSlug = urlSuggestion.split(`/${route}/`)[1];
    setTextState(newSlug);
  };

  return (
    <Editable
      fieldName="urlPath"
      isValid={!validationMessage.length}
      editNode={
        <>
          <Heading h2 textSize="xl">
            URL
          </Heading>
          <Text>
            You may edit the URL only once. Once your URL has been changed, it
            can not be changed again.
          </Text>
          <Text>Can only contain letters, numbers and hyphens.</Text>
          <EditableTextField
            text={textState}
            setCurrentValue={(value) => {
              setTextState(value);
            }}
            validation={{
              type: "textField",
              maxLength: url.length < 100 ? 100 : url.length,
              minLength: 3,
              regex: {
                pattern: urlRegex,
                message: urlRegexMessage,
              },
            }}
            extraTop={
              <Text wordBreak="break-all">
                {`${isBrowser ? window.location.origin : ""}/page/`}
              </Text>
            }
            validationMessage={validationMessage}
            setValidationMessage={setValidationMessage}
            aria-label="URL"
          />
          {urlSuggestion.length ? (
            <>
              <div>
                <ErrorText>
                  That URL is already in use, please try another.
                </ErrorText>
              </div>
              <div>
                <ErrorText marginBottom="xs">
                  {`Available: `}
                  <Button
                    appearance="tertiary"
                    onClick={handleSuggestionClick}
                    data-cta-type="set-suggested-url"
                  >
                    {urlSuggestion}
                  </Button>
                </ErrorText>
              </div>
            </>
          ) : null}
        </>
      }
      viewNode={
        <>
          <Heading h3 textSize="xl">
            URL
          </Heading>
          <Text marginBottom="none">{`Customise the link for your ${
            route === "team" ? "Team" : "Giving"
          } Page`}</Text>
        </>
      }
      handleSubmit={handleSubmit}
      tooltip="Edit url"
    />
  );
};

export default EditableUrlForm;
