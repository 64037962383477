import styled from "styled-components";
import { type ThemeType } from "@cruk/cruk-react-components";

export const ProgressButton = styled.button<{ theme: ThemeType }>`
  font-size: ${({ theme }) => theme.fontSizes.m};
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  text-align: left;
`;

export const RowStretchCentered = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressRow = styled.div<{ theme: ThemeType }>`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.xxs};
`;

export const BlockCentreMobile = styled.div<{ theme: ThemeType }>`
  width: 100%;
  > * {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: auto;
    > * {
      margin-left: ${({ theme }) => theme.spacing.s};
      margin-right: 0;
    }
  }
`;
