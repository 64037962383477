import NextLink from "next/link";
import { useTheme } from "styled-components";
import {
  Link as A,
  IconFa,
  type ThemeType,
  Badge,
} from "@cruk/cruk-react-components";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { formatMoneyWithCommas } from "@fwa/src/utils/formatUtils";
import { userNameForPage } from "@fwa/src/utils/pageUtils";
import { imagePathFromImageType } from "@fwa/src/services/imageService";

import {
  ShadowWrapper,
  ProfileImageWrapper,
  ProfileImage,
  MemberLink,
  TextWrapper,
  ProfileOuterWrapper,
  LeaderStar,
} from "@fwa/src/components/MemberFundraisingPage/styles";

import { Row, SingleLineElipsis } from "@fwa/src/components/styles";

import { type MemberFundraisingPageRendererProps } from "@fwa/src/types";
import { memberPageIconUrlForTheme } from "@fwa/src/utils/memberUtils";

export const MemberFundraisingPage = ({
  fundraisingPage,
  isLeader = false,
  children,
}: MemberFundraisingPageRendererProps) => {
  const { fundraiser, title } = fundraisingPage;
  const name = userNameForPage(fundraisingPage);
  const raisedAmount = fundraisingPage?.donationsTotalAmount
    ? formatMoneyWithCommas(fundraisingPage?.donationsTotalAmount).replace(
        ".00",
        "",
      )
    : "0";

  const theme = useTheme() as ThemeType;

  const iconUrl = memberPageIconUrlForTheme(theme.name);

  const profileImageUrl =
    fundraiser?.profileImage?.entityType === "ImageFacebook"
      ? `${fundraiser.profileImage.url}?width=300&height=300`
      : fundraiser?.profileImage
        ? imagePathFromImageType(fundraiser?.profileImage)
        : "";

  return (
    <ShadowWrapper data-component="member-fundraising-page">
      <NextLink
        href={`/page${fundraisingPage.url.slice(
          fundraisingPage.url.lastIndexOf("/"),
        )}`}
        data-cta-type="link-page"
      >
        <MemberLink aria-label={`${fundraisingPage.title}`}>
          <Row>
            <ProfileOuterWrapper>
              <ProfileImageWrapper $isLeader={isLeader}>
                {fundraiser.profileImage ? (
                  <ProfileImage src={profileImageUrl} alt="" />
                ) : (
                  <img src={iconUrl} alt="" />
                )}
              </ProfileImageWrapper>
              {!!isLeader && (
                <LeaderStar>
                  <Badge backgroundColor="secondary" size="s">
                    <IconFa faIcon={faStar} color="#FFF" size="2em" />
                  </Badge>
                </LeaderStar>
              )}
            </ProfileOuterWrapper>

            <TextWrapper marginLeft="xs">
              <SingleLineElipsis textWeight={700} marginBottom="none">
                {`${name}${isLeader ? " (Team Leader)" : ""}`}
              </SingleLineElipsis>
              <SingleLineElipsis marginBottom="none">
                <A as="span">{`${title}`}</A>
              </SingleLineElipsis>
              <SingleLineElipsis marginBottom="none">
                {`£${raisedAmount} raised`}
              </SingleLineElipsis>
            </TextWrapper>
          </Row>
        </MemberLink>
      </NextLink>

      {children}
    </ShadowWrapper>
  );
};

export default MemberFundraisingPage;
